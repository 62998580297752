a {
  color: black;
}

.rbc-month-row+.rbc-month-row {
  border-top: 0px;
  background-color: white;
}

.rbc-day-bg+.rbc-day-bg {
  border: 1px solid #F4F4F4;
}

.rbc-day-bg {
  border-radius: 12px;
  background-color: #F4F4F4;
  margin: 0px 4px;
  border: 1px solid #F4F4F4;
}

.rbc-today {
  background-color: #EEE9FC;
}

.rbc-off-range-bg {
  background-color: white;
}

.rbc-month-row {
  margin: 4px;
}

.rbc-button-link {
  color: #7C7C7C;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 600;
  padding: 0px 8px;
}

.rbc-off-range .rbc-button-link {
  color: #CBCBCB;
}

.rbc-date-cell {
  text-align: left;
  padding: 4px;
  height: 30px;
}

.rbc-month-view {
  border: 0px;
}

.rbc-header {
  border-bottom: 0px;
  border-radius: 12px;
  color: #4E4E4E;
  background-color: #F4F4F4;
  margin: 4px;
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
}

.rbc-time-view .rbc-row {
  min-height: 50px;
  margin-bottom: 8px;
}

.rbc-header+.rbc-header {
  border-left: 0px;
}

.rbc-event {
  width: 90%;
  margin: auto;
  background-color: #7CB0FF;
  color: #1F2C40;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.rbc-row-segment {
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
}

.rbc-show-more {
  font-size: 14px;
  font-weight: 500;
  color: #593E9C;
}

.rbc-event.rbc-selected {
  background-color: #7CB0FF;
}

.rbc-time-slot {
  font-family: "Roboto";
  font-size: 14px;
}

.rbc-label{
  color: #817B90;
  font-weight: 400;
}

.rbc-day-slot .rbc-time-slot{
  border: 0px
}

.rbc-timeslot-group {
  border-color: #E9E0FF;
}

.rbc-time-column {
  width: 78.1875px;
}

.rbc-time-header-content{
  border-left: 0px;
  padding: 8px;
}

.rbc-time-content{
  border-top: 1px solid #ddd;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
  border: 0px;
}